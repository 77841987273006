import "./Card3.css"
import video from "../../../Photos/4K.mp4"
import { Link, useParams } from "react-router-dom"
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"


import OrderFinishing from "./OrderComplation/OrderFinishing";
import Notify from "./Notify/Notify";
import OrderFinishing2 from "./OrderComplation/OrderFinishing2/OrderFinishing2";
import NavbarForAll from "../../Components/NavbarForAll/NavbarForAll";
import FooterForAll from "../../Components/FooterForAll/FooterForAll";

export default function Card3() {
    const { id } = useParams();
    const [selectedValue, SetselectedValue] = useState();
    const handleonChange = (event) => {
        SetselectedValue(event.target.value);

    }
    const [datas, SetDatas] = useState([]);
    const [haveColor, SetHaveColor] = useState(false);
    const [lastcheckedbutton, SetLastcheckedbutton] = useState(null);
    const [productColors, SetProductColors] = useState([]);
    const [selectimg, Setselectedimg] = useState();
    const [showOrderFinishing, setShowOrderFinishing] = useState(false);
    const [buttonStates, setButtonStates] = useState({});
    const [isClicked, SetisClicked] = useState(false);
    const [getedimage, Setgetedimage] = useState([]);
    const buttonHandler = (buttonColor) => {
        SetLastcheckedbutton(buttonColor);
        setButtonStates((prevStates) => ({
            ...prevStates,
            [buttonColor]: !prevStates[buttonColor],
        }));

    }

    const addBasketHandler = (color) => {
        console.log('====================================');
        console.log(color);
        console.log('====================================');
        if (haveColor) {
            if (color != null) {
                setShowOrderFinishing(true);
                SetisClicked(false);
    
            }
            else {
                SetisClicked(true);
            }
        }
        else{
            setShowOrderFinishing(true);
            SetisClicked(false);
        }


    }
    const foundthecolors = (response) => {
        console.log(response.productColors);
        const productcolors = response.productColors;

        const pcolors = [];
        let num = 0;
        let lastindex = 0;

        const buttonClickHandler = (item) => {
            Setselectedimg(item);
        }

        for (let index = 0; index < productcolors.length; index++) {
            if (productcolors[index] == ",") {
                pcolors[num] = "";
                if (num != 0) {
                    for (let index2 = lastindex + 1; index2 < index; index2++) {
                        pcolors[num] += productcolors[index2];
                    }
                }
                if (num == 0) {
                    for (let index2 = lastindex; index2 < index; index2++) {
                        pcolors[num] += productcolors[index2];
                    }
                }

                lastindex = index;
                num++;
            }
        }
        if (pcolors.length > 0) {  // Kontrolü buraya ekleyin
            SetProductColors(pcolors);
            SetHaveColor(true);  // Renkler varsa 'true' yapın
        } else {
            SetHaveColor(false);  // Renk yoksa 'false' yapın
        }

    }
    const [firstselectimage, Setfirstselectimage] = useState();
    useEffect(() => {
        axios.get("https://focusonme.azurewebsites.net/product/get/vmid?givedid=" + id).then(response => {
            console.log(response.data);
            SetDatas(response.data);
            foundthecolors(response.data);

        });
        axios.get("https://focusonme.azurewebsites.net/api/Productimge/imgid?id=" + id).then(response => {
            Setgetedimage(response.data);
            Setfirstselectimage(require(`../../../Photos/${response.data[0]}.png`));

        });



    }, [])

    return (
        <div className="Card3_div" style={{ fontFamily: 'dasdas' }}>
            {showOrderFinishing && <OrderFinishing2 verify={true} color={haveColor?selectedValue:"Dont Have Color:  NoN Color"} id={id} />}
            <NavbarForAll></NavbarForAll>

            <div className="Card3_div_ItemShowing" style={{ fontFamily: 'dasdas' }} >
                <div className="Card3_div_ItemShowing_div1" style={{ height: '1100px', fontSize: '25px', overflow: "hidden" }}>
                    <div></div>
                    <div>
                        <p>SS</p>

                        <img style={{ width: "100%", height: "65%", borderRadius: '20px' }} src={firstselectimage}></img>
                        {
                            getedimage.map((item, index) => {
                                return (
                                    <img key={index} className="Card3_div_ItemShowing_div1_img_divs3" onClick={() => { Setfirstselectimage(require(`../../../Photos/${item}.png`)); }} src={item ? require(`../../../Photos/${item}.png`) : null} style={{ fontSize: '15px', borderRadius: '10px', margin: '2px', border: '0px' }}></img>
                                )
                            })
                        }

                    </div>

                </div>

                <div className="Card3_div_ItemShowing_div2" style={{ fontFamily: 'dasdas' }}>
                    <div></div>
                    <div>
                        <p style={{ fontSize: '30px', fontFamily: "smokeone" }}>{datas.productName}</p>
                        <p className="Card3_div_ItemShowing_div2_p" style={{ fontSize: '30px', font: 'arial' }}>{datas.productPrice}</p>
                        <br />

                        <div style={{ display: haveColor ? "block" : "none" }}>
                            {
                               
                                console.log(haveColor)
                            }
                            <p style={{ fontSize: '25px' }} >Colors</p>
                            <div>
                                <select style={{ backgroundColor: 'white', color: 'black', fontFamily: 'smokeone' }} value={selectedValue} onChange={handleonChange} class="form-select" aria-label="Default select example">
                                    <option selected>Select Color</option>

                                    {
                                        productColors.map((item, index) => {
                                            return (

                                                <option value={item}>{item}</option>

                                            )
                                        })
                                    }

                                </select>
                            </div>
                            <br />
                        </div>



                        <button className="Card3_div_ItemShowing_div2_button2 " onClick={() => addBasketHandler(selectedValue)} style={{ border: '0px', width: '75%', color: 'black', height: '50px', fontFamily: 'dasdas', fontSize: '20px', borderRadius: '100px' }}>Add To Basket</button>
                        <br />
                        <br /><br />
                        <p style={{ fontSize: '25px' }}>Description:</p>
                        <p className="Card3_div_ItemShowing_div2_des">
                            {datas.productDescription}
                        </p>

                    </div>
                </div>





            </div>

            <FooterForAll></FooterForAll>

            <div>
                <video autoPlay loop muted playsInline className="Card3_div_video" src={video}></video>
            </div>
        </div>
    )
}