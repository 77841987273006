import React from 'react';
import { Link } from 'react-router-dom';

function NavbarForAll() {
  return (
    <div>
          <div style={{ display: 'block' }}>
                <div className="Card3_div_navbar">
                    <p></p>
                    <div className="Card3_div_navbar_div2">
                    <Link to={"/"} style={{ textDecoration: 'none', color: 'white'}}>

                            <p>blankets</p>

                        </Link>
                    </div>
                </div>
            </div>

    </div>
  );
}

export default NavbarForAll;
