import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home1 from './Components/Home/Home1/Home';
import Card3 from './Components/Cards/Card3/Card3';
import Help1 from './Components/help/help1';
import Aboutus1 from './Components/aboutus/aboutus1';

const router=createBrowserRouter([
  {
    path:'/',
    element:<Home1/>
  },
  {
    path:'/product/:id',
    element:<Card3/>
  },
  {
    path:"/help",
    element:<Help1/>
  },
  {
    path:"/aboutus",
    element:<Aboutus1/>
  }

])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
