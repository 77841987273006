import React from 'react';
import { Link } from 'react-router-dom';

function HomeItemCards(props) {
  return (
    <div>
      <div className="Home1_div_Items_div_div2_divs">

        <div></div>
        <div className="Home1_div_Items_div_div2_divs_div2s">
          <div>
            <Link  to={"product/"+props.productId}>
              <img style={{ width: '100%', height: '100%', borderRadius: '10px'}} src={props.img2} alt="" />

            </Link>
          </div>
          <div className="Home1_div_Items_div_div2_divs_div2s_div2">
            <p className="Home1_div_Items_div_div2_divs_div2s_ps">{props.title}</p>
            <p className="Home1_div_Items_div_div2_divs_div2s_ps2">{props.price}</p>

          </div>
        </div>

      </div>

    </div>
  );
}

export default HomeItemCards;
