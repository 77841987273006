import "./Home1.css";
import video from "../../../Photos/4K.mp4";
import axios from "axios";
import { useEffect, useState } from "react";
import HomeItemCards from "./HomeItemCard/HomeItemCards";
import FooterForAll from "../../Components/FooterForAll/FooterForAll";
import NavbarForAll from "../../Components/NavbarForAll/NavbarForAll";
import HeaderForAll from "../../Components/HeaderForAll/HeaderForAll";
import HomeVideoDiv from "./HomeVideoDiv/HomeVideoDiv";
import HomeFooterDiv from "./HomeFooterDiv/HomeFooterDiv";

export default function Home1() {
    const [responsee, SetResponse] = useState([]);
    const [price, SetPrice] = useState();
    const [selectedImages, setSelectedImages] = useState({});
    const [imagesLoaded, setImagesLoaded] = useState(false); // Yeni durum değişkeni

    let fulllenght = 0;

    useEffect(() => {
        axios.get("https://focusonme.azurewebsites.net/api/productcat/wantedbycat?wantedcategory="+"Dear").then((response) => {
            SetResponse(response.data);
            console.log(response.data);
        });
    }, []);

    const getImagePath = async (item) => {
        try {
            const response = await axios.get(
                "https://focusonme.azurewebsites.net/api/Productimge/imgid?id=" + item.productId
            );
            const selectedImage = response.data[0];
            setSelectedImages((prevImages) => ({ ...prevImages, [item.productId]: selectedImage }));
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    useEffect(() => {
        const loadImageData = async () => {
            for (const item of responsee) {
                if (item.productPrice != null && item.productId != null) {
                    
                    await getImagePath(item);
                }
            }
            setImagesLoaded(true); // Tüm resimler yüklendiğinde durumu güncelle
        };

        loadImageData();
    }, [responsee]);

    return (
        <div className="Home1_div" style={{ fontFamily: "dasdas" }}>
            <div className="Home1_div_itemandfooter">
                <div>
                    <NavbarForAll></NavbarForAll>
                    <HeaderForAll></HeaderForAll>
                    <div className="Home1_div_Items">
                        <div className="Home1_div_Items_div">
                            <div></div>
                            <div className="Home1_div_Items_div_div2">
                                {
                                    responsee.map((item, index) => {
                                      
                                            fulllenght++;
                                            console.log('====================================');
                                            console.log(item);
                                            console.log('====================================');
                                            return (
                                            <HomeItemCards
                                                key={item.productId}
                                                price={item.productPrice}
                                                productId={item.productId}
                                                title={item.productName}
                                                img2={selectedImages[item.productId] ? require(`../../../Photos/${selectedImages[item.productId]}.png`) : null}
                                            ></HomeItemCards>
                                        )
                                     
                                     
                                    }
                                    )} 
                                    
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <HomeFooterDiv arraylenght={fulllenght}></HomeFooterDiv>
                </div>
            </div>
            <HomeVideoDiv video={video} arraylenght={fulllenght}></HomeVideoDiv>
        </div>
    );
}