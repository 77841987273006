import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import { ValidationError, useForm } from '@formspree/react';
import FooterForAll from '../Components/FooterForAll/FooterForAll';
import HeaderForAll from '../Components/HeaderForAll/HeaderForAll';
import NavbarForAll from '../Components/NavbarForAll/NavbarForAll';
function Help1() {
  const [state, handleSubmit] = useForm("mzbnznjp");
  if (state.succeeded) {
    return <p> Thanks for Contacting us.<br></br>Estimated return : 1 hour.<br></br> Aviable Personal Emails:ahmetturan8873@gmail.com. Estimated return: 10 min.<br></br> Have a Good Day. </p>;
  }

  return (
    <div>
    <NavbarForAll></NavbarForAll>
    <HeaderForAll></HeaderForAll>
    <div style={{margin:'20px'}}>
      <h1 style={{ fontSize: '40px', fontWeight: 'bolder' }}>Contact us</h1>
      <br></br>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            aria-describedby="emailHelp"
          />
          <div id="emailHelp" className="form-text">
            We ll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            Phone Number
          </label>
          <input
            type="tel"
            name="phone"
            className="form-control"
            id="phone"
            
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">Whats your issue</label>
          <textarea   name="message" class="form-control" id="message" rows="3"></textarea>
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="exampleCheck1" />
          <label className="form-check-label" htmlFor="exampleCheck1">
            Check me out
          </label>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>

    </div>
    <FooterForAll/>

    </div>
  );
}

export default Help1;
