import React, { useEffect, useState } from 'react';

function HomeVideoDiv(props) {
  const [videoHeight,SetvideoHeight]=useState(2000);
  const [arraylenght,Setarraylenght]=useState(600);
  useEffect(()=>{
    Setarraylenght(props.arraylenght);
    videoheightConfiger(props.arraylenght);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[props.arraylenght])
  const videoheightConfiger=(alength)=>{
    if (alength>=4) {
      if (window.innerWidth>800) {
        const vidheight=620*alength;
        SetvideoHeight(vidheight);
      }
      if (window.innerWidth<700) {
        const vidheight=810*alength;
        SetvideoHeight(vidheight);
      }
    }
 
   
  }
  const handleResize=()=>{
    videoheightConfiger(props.arraylenght);
  }
  return (
    <div>
      <div>
        <video autoPlay loop playsInline muted className="Home1_div_video" style={{minHeight:videoHeight}}  src={props.video}></video>
      </div>
    </div>
  );
}

export default HomeVideoDiv;
