import React, { useEffect, useState } from 'react';

function HomeFooterDiv(props) {
  const [videoHeight, SetvideoHeight] = useState(2000);
  const [arraylenght, Setarraylenght] = useState();
  useEffect(() => {
    Setarraylenght(props.arraylenght);
    videoheightConfiger(props.arraylenght);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.arraylenght])
  const videoheightConfiger = (alength) => {
    if (alength>=4) {
      if (window.innerWidth > 800) {
        const vidheight = 620  * alength;
        SetvideoHeight(vidheight);
      }
      if (window.innerWidth < 700) {
        const vidheight = 810 * alength;
        SetvideoHeight(vidheight);
      }
    }


  }
  const handleResize = () => {
    videoheightConfiger(props.arraylenght);
  }
  return (
    <div>
      <div style={{ top: videoHeight }}  className="Home1_div_Footer">
        <div className="Card3_div_ItemShowing_footer_div" >
          <div>
            <p className="Card3_div_ItemShowing_footer_div_psH">Our Info</p>
            <p className="Card3_div_ItemShowing_footer_div_psh">atlndsp@gmail.com</p>
          </div>
          <div>
            <p className="Card3_div_ItemShowing_footer_div_psH">Stores</p>
            <p className="Card3_div_ItemShowing_footer_div_psh">Heat Up</p>
            <p className="Card3_div_ItemShowing_footer_div_psh">anotherland.com</p>

          </div>
          <div>
            <p className="Card3_div_ItemShowing_footer_div_psH">E-mail Us</p>
            <p className="Card3_div_ItemShowing_footer_div_psh">Heat Up</p>
            <p className="Card3_div_ItemShowing_footer_div_psh">anotherland.com</p>
          </div>

        </div>
        <div style={{ justifyContent: 'center', textAlign: 'center' }}>
          <p>All Your Transiction Saving To Solana Blockchain By AnotherLand Nfts</p>
        </div>
      </div>
    </div>
  );
}

export default HomeFooterDiv;
