import React from 'react';

function HeaderForAll() {
  return (
    <div>
      <div className="Home1_div_Header">
        <p></p>
        <p className="Home1_div_Header_p">ATLND</p>
      </div>

    </div>
  );
}

export default HeaderForAll;
