import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import FooterForAll from '../Components/FooterForAll/FooterForAll';
import { Link } from 'react-router-dom';

function Aboutus1() {
  return (
    <div>
    <main>
  <div className="container py-4">
    <header className="pb-3 mb-4 border-bottom">
     Dear Blankets
    </header>
    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
      <div className="container-fluid py-5">
        <h1 className="display-5 fw-bold">We are littel family company</h1>
        <p className="col-md-8 fs-4">
          We tryna make our make our soup money! We are little family in Miami,since 1990 we making
          ecommerce with more than 15000+ selling experince 
        </p>
      </div>
    </div>
    <div className="row align-items-md-stretch">
      <div className="col-md-6">
        <div className="h-100 p-5 text-bg-dark rounded-3">
          <h2>Thanks for supporting</h2>
          <p>
            Your transictions saving solana and atlnd blockchain, its mean no one can scam you 
            stay relax
          </p>
          <a href='https://en.wikipedia.org/wiki/Blockchain'  className="btn btn-outline-light" type="button">
            What is Blockchain
          </a>
        </div>
      </div>
      <div className="col-md-6">
        <div className="h-100 p-5 bg-body-tertiary border rounded-3">
          <h2>You can connect our Order Manager Private email</h2>
          <p>
            ahmetturan8875@gmail.com
          </p>
          <Link to={"/help"} className="btn btn-outline-secondary" type="button">
            SEND EMAIL
          </Link>
        </div>
      </div>
    </div>
    <footer className="pt-3 mt-4 text-body-secondary border-top">© 2023</footer>
  </div>
</main>
  <FooterForAll></FooterForAll>
    </div>
  );
}

export default Aboutus1;
