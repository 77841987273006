import React, { useEffect, useState } from 'react';
import "./OrderFinishin2.css"
import "bootstrap-icons/font/bootstrap-icons.min.css"
import axios from 'axios';
function OrderFinishing2(props) {
  const [firstname,Setfirstname]=useState();
  const [phone,Setphone]=useState();
  const [email,Setemail]=useState();
  const [insideofinput,Setinsideofinput]=useState(null);
  const [datas,Setdatas]=useState([]);
  const [tempnumber1,Settempnumber1]=useState(0);
  const [isclosed,Setisclosed]=useState(true);
  const [link,SetLink]=useState();
  const emtyinsideofinput=(inputvalue)=>{
    const newdatas=[...datas];
    newdatas.push(inputvalue);
    Setdatas(newdatas);
    Setinsideofinput(null);
  }
  const sendbuttoncatcher= async (data)=>{
    if (data[0]&&data[1] !=null ) {
      Setfirstname(data[0]);
      Setphone(data[1]);
      Setphone(data[2]);
      console.log('====================================');
      console.log(data[0]);
      console.log('====================================');
      console.log('====================================');
      console.log(data[1]);
      console.log('====================================');
      console.log('====================================');
      console.log(data[2]);
      console.log('====================================');
      const response=await sendApi(data);
    }

  }
  useEffect( ()=>{
    axios.get("https://focusonme.azurewebsites.net/api/StripeValues/GetLink?linksid=" + props.id).then(response => SetLink(response.data));
  
  })
  const sendApi = async (data) => {
    const newlink="";
    try{
       const response = await axios.post("https://focusonme.azurewebsites.net/api/order/order/temp?ProductColors="+props.color);
        console.log('====================================');
        console.log(link);
        console.log(props.id);
        console.log('====================================');
        sendurl(link);
    }
    catch (err){
        console.log('====================================');
        console.log(err);
        console.log('====================================');
    }
   
  
  };
  const  sendurl=  (link)=>{
    window.location.href=link;
  }
  const handleClose=()=>{
    Setisclosed(false);
  }
  return (
    <div className='OrderFinishing2_div' style={{display:`${isclosed===true ? "block":"none"}`}}>
        <div className='OrderFinishing2_div_div2'>
            <p className='OrderFinishing2_div_div2_forupperwantedmessages'>hello</p>
            <p className='OrderFinishing2_div_div2_p2'>first name <p className='OrderFinishing2_div_div2_p2_p'>please</p></p>
            <div className='OrderFinishing2_div_div2_div1' style={{opacity:`${datas.length<1 ?'0' :'1'}`}}><p className='OrderFinishing2_div_div2_div1_p'>{datas[0]}</p></div>
            <p className='OrderFinishing2_div_div2_p3' style={{opacity:`${datas.length<1 ?'0' :'1'}`}}>phone number </p>
            <div className='OrderFinishing2_div_div2_div2' style={{opacity:`${datas.length<2 ?'0' :'1'}`}}><p className='OrderFinishing2_div_div2_div1_p' style={{fontFamily:'smokeone',fontSize:"12px",bottom:'5px'}}>{datas[1]}</p></div>
            <p className='OrderFinishing2_div_div2_p4' style={{opacity:`${datas.length<2 ?'0' :'1'}`}}>e-mail</p>
            <div className='OrderFinishing2_div_div2_div2' style={{opacity:`${datas.length<3 ?'0' :'1'}`}}><p className='OrderFinishing2_div_div2_div1_p' style={{fontFamily:'smokeone',fontSize:"12px",bottom:'5px'}}>{datas[2]}</p></div>
            <p className='OrderFinishing2_div_div2_p5' style={{opacity:`${datas.length<3 ?'0' :'1'}`}}>Thanks! For check out click it</p>
            <button onClick={()=>sendbuttoncatcher(datas)} className='OrderFinishing2_div_div2_button1' style={{opacity:`${datas.length<3 ?'0' :'1'}`}}>Send</button>
            <div className='OrderFinishing2_div_div2_div3'>
              <input onChange={(a)=>Setinsideofinput(a.target.value)} value={insideofinput} className='OrderFinishing2_div_div2_div3_input'></input>
              <button onClick={()=>emtyinsideofinput(insideofinput)} style={{position:'relative',left:'0px',width:'30%',height:'50px',top:'0px',borderRadius:'10px',backgroundColor:'#007bff',color:'white',border:'0px'}}>Send</button>
              {/* <i  class="bi bi-arrow-up-circle OrderFinishing2_div_div2_div3_is"></i>
              <i onClick={()=>handleClose()} class="bi bi-upload OrderFinishing2_div_div2_div3_is"></i> */}
            </div>
        </div>

    </div>
  );
}

export default OrderFinishing2;
