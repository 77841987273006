import React from 'react';
import { Link } from 'react-router-dom';

function FooterForAll() {
  return (
    <div>
      
      <div className="Card3_div_ItemShowing_footer">
                <div className="Card3_div_ItemShowing_footer_div">
                    <div>
                        <Link to={"/help"} className="Card3_div_ItemShowing_footer_div_psH">Help</Link>
                        <p className="Card3_div_ItemShowing_footer_div_psh">ahmetturan8875@gmail.com</p>
                    </div>
                    <div>
                        <p className="Card3_div_ItemShowing_footer_div_psH">Stores</p>
                        <p className="Card3_div_ItemShowing_footer_div_psh">Heat Up</p>
                        <p className="Card3_div_ItemShowing_footer_div_psh">anotherland.com</p>

                    </div>
                    <div>
                        <Link to={"/help"} className="Card3_div_ItemShowing_footer_div_psH">E-mail Us</Link>

                        <p className="Card3_div_ItemShowing_footer_div_psh">Heat Up</p>
                        <Link to={"/aboutus"} className="Card3_div_ItemShowing_footer_div_psH">AboutUs</Link>

                        <p className="Card3_div_ItemShowing_footer_div_psh">anotherland.com</p>
                    </div>

                </div>
                <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <p>All Your Transiction Saving To Solana Blockchain By AnotherLand Nfts</p>
                </div>
            </div>
    </div>
  );
}

export default FooterForAll;
